import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IMenuAppBarBtn } from '../../models/CommonModels';

const activeStyle = {
    color: 'white',
    flexDirection: 'column',
    gap: 0.5,
    backgroundColor: '#ffffff66',
    cursor: 'default'
};

const inactiveStyle = {
    color: 'white',
    flexDirection: 'column',
    gap: 0.5
};

const disabledStyle = {
    color: 'white',
    flexDirection: 'column',
    gap: 0.5
};

interface IProps {
    item: IMenuAppBarBtn;
    active?: boolean;
    onClick: (path: string) => void;
}
const MenuBtn = (props: IProps) => {
    const { item, active = false, onClick } = props;
    const { t } = useTranslation();

    const onClickHandler = useCallback(() => {
        if (!active) {
            onClick(item.path);
        }
    }, [active, item.path, onClick]);

    const style: {} = item.disabled ? disabledStyle : active ? activeStyle : inactiveStyle;

    return (
        <Tooltip title={t(item.tooltip)} placement={'bottom'} arrow>
            <span>
                <Button
                    sx={style}
                    onClick={onClickHandler}
                    disabled={item.disabled}
                >
                    {item.icon}
                    <Typography variant='caption'>{t(item.label)}</Typography>
                </Button>
            </span>
        </Tooltip>
    );
}
export default MenuBtn;