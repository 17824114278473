import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useLoad } from "../../../hooks/useLoad";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { ELoadStatus } from "../../../models/LoadModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import DateRangeField from "../../Base/DateComponent/DateRangeField";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const LoadFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useLoad();
    const { criterias } = useSelector((state: RootState) => state.loadSlice.grid);

    const [idno, setIdno] = useState<string>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [status, setStatus] = useState<ELoadStatus | undefined>(undefined);
    const [dueDateFrom, setDueDateFrom] = useState<number | undefined>(undefined);
    const [dueDateTo, setDueDateTo] = useState<number | undefined>(undefined);

    const statusData = useRef<string[]>(
        Object.keys(ELoadStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== ELoadStatus[ELoadStatus.NONE])
            .map(key => key.toString())
    );

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || '');
        setCustomerId(getValueFromCriterias('customer.uuid', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as ELoadStatus || ELoadStatus.NONE);

        const dueDateRangeStr: string = getValueFromCriterias('deliveredBy', criterias) || '';
        if (isBlank(dueDateRangeStr)) {
            setDueDateFrom(undefined);
            setDueDateTo(undefined);
        } else {
            const dueDateRange: string[] = dueDateRangeStr.split(',');
            setDueDateFrom(Number(dueDateRange[0]));
            setDueDateTo(Number(dueDateRange[1]));
        }
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIdno(event.target.value);
    }, []);

    const onChangeCustomerHandler = useCallback((customer: ICustomerAutocompleteResponseDto | null) => {
        setCustomerId(customer?.uuid || '');
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as ELoadStatus);
    }, []);

    const onChangeDueDateRangeHandler = useCallback((fromDate?: number, toDate?: number) => {
        setDueDateFrom(fromDate);
        setDueDateTo(toDate);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isBlank(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (customerId) {
            newCriterias.push({
                property: 'customer.uuid',
                value: customerId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (status) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (dueDateFrom && dueDateTo) {
            newCriterias.push({
                property: 'deliveredBy',
                value: `${dueDateFrom},${dueDateTo}`,
                expression: ECriteriaExpression.BETWEEN_DATE
            });
        }

        return newCriterias;
    }, [customerId, dueDateFrom, dueDateTo, idno, status]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('ID#')}
                        autoComplete='off'
                        slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                        value={idno}
                        onChange={onChangeIdnoHandler}
                    />

                    <DateRangeField
                        alignDirection='row'
                        label={t('DUE DATE')}
                        requiredToFrom
                        fromDateLabel={t('FROM')}
                        toDateLabel={t('TO')}
                        toDateValue={dueDateTo}
                        fromDateValue={dueDateFrom}
                        onChange={onChangeDueDateRangeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <CustomerAutocomplete
                        label={t('CUSTOMER')}
                        value={customerId}
                        size='small'
                        onChange={onChangeCustomerHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData.current}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [customerId, dueDateFrom, dueDateTo, idno, onChangeCustomerHandler, onChangeDueDateRangeHandler,
        onChangeIdnoHandler, onChangeStatusHandler, status, t]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default LoadFilter;