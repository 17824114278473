import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBlank } from '../../../helpers/textHelper';
import { IAddressShortResponseDto } from '../../../models/AddressModels';
import AddressDialog from './AddressDialog';

const defaultAddress: IAddressShortResponseDto = {
    uuid: '',
    value: ''
};

interface IProps {
    required?: boolean;
    value: IAddressShortResponseDto | undefined;
    onInit?: (value: IAddressShortResponseDto | undefined) => void;
    onChange: (address: IAddressShortResponseDto) => void;
}
const Address = (props: IProps) => {
    const { required = false, onInit, onChange, value } = props;

    const [addressDialogToggle, setAddressDialogToggle] = useState(false);
    const [address, setAddress] = useState<IAddressShortResponseDto>(value || defaultAddress);

    const { t } = useTranslation();

    useEffect(() => {
        if (onInit) {
            onInit(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddressDialogToggleHandler = useCallback(() => {
        setAddressDialogToggle(!addressDialogToggle);
    }, [addressDialogToggle]);

    const onApplyBtnClickHandler = useCallback((address: IAddressShortResponseDto) => {
        onChange(address);
        setAddress(address);
    }, [onChange]);

    const onClearBtnClickHandler = useCallback(() => {
        onChange(defaultAddress);
        setAddress(defaultAddress);
    }, [onChange]);

    const onAddressTextFieldAdornmentBuild = useCallback(() => {
        return ({
            endAdornment: <InputAdornment position='start'>
                <IconButton edge='end' onClick={onClearBtnClickHandler}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            </InputAdornment>
        });
    }, [onClearBtnClickHandler]);

    return (
        <Box>
            <Stack direction='row' spacing={1}>
                <Tooltip title={t('Select an address')}>
                    <Button
                        variant='contained'
                        onClick={onAddressDialogToggleHandler}
                        color='info'
                    >
                        <AddLocationAltIcon />
                    </Button>
                </Tooltip>

                <Tooltip title={address?.value}>
                    <TextField
                        required={required}
                        label={t('ADDRESS')}
                        multiline
                        rows={2}
                        sx={{
                            '.MuiInputBase-input': {
                                fontSize: '0.8rem',
                                lineHeight: '1em'
                            }
                        }}
                        slotProps={{
                            htmlInput: { readOnly: true },
                            input: isBlank(address.uuid) ? {} : onAddressTextFieldAdornmentBuild()
                        }}
                        fullWidth
                        value={address?.value}
                    />
                </Tooltip>
            </Stack>

            {addressDialogToggle &&
                <AddressDialog
                    open={addressDialogToggle}
                    onApplyBtnClick={onApplyBtnClickHandler}
                    onCloseBtnClick={onAddressDialogToggleHandler}
                />
            }
        </Box >
    );
}
export default Address;