import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from "@mui/material";
import { useCallback, useState } from 'react';

interface IProps {
    disabled?: boolean;
}
const NotificationBadgeBtn = (props: IProps) => {
    const { disabled } = props;
    const [count, setCount] = useState<number>(0);

    const onClickHandler = useCallback(() => {

    }, []);

    return (
        <IconButton
            size='large'
            aria-label='Display notifications'
            color='inherit'
            disabled={disabled}
            onClick={onClickHandler}
        >
            <Badge badgeContent={count} color='error'>
                <NotificationsIcon />
            </Badge>
        </IconButton>
    );
}
export default NotificationBadgeBtn;