import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import MenuAppBar from '../components/MenuModule/MenuAppBar';

const pageMainStyle = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
};

const pageContentStyle = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px'
};

const LayoutPage = () => {
    return (
        <Box sx={pageMainStyle}>
            <MenuAppBar />

            <Box component="main" sx={pageContentStyle}>
                <Outlet />
            </Box>
        </Box>
    );
}
export default LayoutPage;