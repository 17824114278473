import { Box, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";

interface IProps {
    label?: string;

    fromDateLabel?: string;
    fromDateValue?: number;

    toDateLabel?: string;
    toDateValue?: number;

    errorMessage?: string;
    size?: 'small' | 'medium';
    alignDirection?: 'row' | 'column';

    required?: boolean;
    requiredToFrom?: boolean;

    onChange?: (fromDate?: number, toDate?: number, error?: string) => void;
}
const DateRangeField = (props: IProps) => {
    const {
        label = 'DATE RANGE', fromDateLabel = 'FROM', toDateLabel = 'TO', size = 'small',
        alignDirection = 'column', required = false, requiredToFrom = false, onChange
    } = props;

    const [fromDateValue, setFromDateValue] = useState<any>(props.fromDateValue ? dayjs(props.fromDateValue) : null);
    const [toDateValue, setToDateValue] = useState<any>(props.toDateValue ? dayjs(props.toDateValue) : null);

    const processChangeEvent = useCallback((fromDate: any, toDate: any) => {
        if (onChange) {
            const from: Dayjs = dayjs(fromDate);
            const to: Dayjs = dayjs(toDate);

            const timestampFrom: number = from.toDate().getTime();
            const timestampTo: number = to.toDate().getTime();

            onChange(timestampFrom, timestampTo);
        }
    }, [onChange]);

    const onChangeFromDateHandler = useCallback((newValue: any) => {
        setFromDateValue(newValue);
        processChangeEvent(newValue, toDateValue);
    }, [processChangeEvent, toDateValue]);

    const onChangeToDateHandler = useCallback((newValue: any) => {
        setToDateValue(newValue);
        processChangeEvent(fromDateValue, newValue);
    }, [fromDateValue, processChangeEvent]);

    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={0.5} direction={alignDirection} >
                <DatePicker
                    label={`${label} ${fromDateLabel}`}
                    maxDate={toDateValue}
                    value={fromDateValue}
                    slotProps={{
                        textField: {
                            required: required,
                            fullWidth: true,
                            size: size,
                            InputLabelProps: { shrink: true },
                            error: requiredToFrom && toDateValue !== null && fromDateValue === null
                        },
                        field: { clearable: true },
                        actionBar: {
                            actions: ['today', 'clear']
                        }
                    }}
                    onChange={onChangeFromDateHandler}
                />

                <DatePicker
                    label={`${label} ${toDateLabel}`}
                    minDate={fromDateValue}
                    value={toDateValue}
                    slotProps={{
                        textField: {
                            required: required,
                            fullWidth: true,
                            size: size,
                            InputLabelProps: { shrink: true },
                            error: requiredToFrom && fromDateValue !== null && toDateValue === null
                        },
                        field: { clearable: true },
                        actionBar: {
                            actions: ['today', 'clear']
                        }
                    }}
                    onChange={onChangeToDateHandler}
                />
            </Stack>
        </Box>
    );
}
export default DateRangeField;