import { Box } from "@mui/material";
import { IMenuAppBarBtn } from '../../models/CommonModels';
import Logo from "../Base/LogoComponent/Logo";
import MenuBtn from './MenuBtn';

interface IProps {
    items: IMenuAppBarBtn[];
    activePath: string;
    onClick: (path: string) => void;
}
const HorizontalMenu = (props: IProps) => {
    const { items, activePath, onClick } = props;

    return (
        <>
            <Box>
                <Logo heigth={55} width={70} color={'white'} />
            </Box>

            <Box marginLeft={4} sx={{ display: 'flex', gap: 1 }}>
                {
                    items.map((item: IMenuAppBarBtn, index: number) => (
                        <MenuBtn
                            key={`menu-item-${index}`}
                            item={item}
                            active={item.path.startsWith(activePath)}
                            onClick={onClick}
                        />
                    ))
                }
            </Box>
        </>
    );
}
export default HorizontalMenu;