import { IFilter } from "../models/CommonModels";
import { EIssueType, IIssueDocumentRequestDto, IIssueRequestDto, IIssueResolveRequest } from "../models/IssueModels";
import { API } from "./APIService";

class IssueService {
    public static fetchDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `issues/${uuid}/documents`);
        return [error, response];
    };

    public static createDocument = async (issueId: string, data: IIssueDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `issues/${issueId}/documents`, data);
        return [error, response];
    };

    public static updateDocument = async (issueId: string, documentId: string, data: IIssueDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `issues/${issueId}/documents/${documentId}`, data);
        return [error, response];
    };

    public static removeDocument = async (issueId: string, documentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `issues/${issueId}/documents/${documentId}`);
        return [error, response];
    };

    public static createDocumentAttachment = async (issueId: string, documentId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `issues/${issueId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
        return [error, response];
    };

    public static removeDocumentAttachment = async (issueId: string, documentId: string, attachmentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `issues/${issueId}/documents/${documentId}/attachments/${attachmentId}`);
        return [error, response];
    };

    public static create = async (data: IIssueRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'issues', data);
        return [error, response];
    };

    public static update = async (uuid: string, data: IIssueRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `issues/${uuid}`, data);
        return [error, response];
    };

    public static remove = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', `issues/${uuid}`);
        return [error, response];
    };

    public static fetchAllByBusinessItem = async (businessItemId: string, type: EIssueType, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `issues/businessItem/${businessItemId}/${type}/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static markInProgress = async (uuid: string): Promise<any> => {
        const [error, response] = await API('POST', `issues/${uuid}/inProgress`);
        return [error, response];
    };

    public static resolve = async (uuid: string, data: IIssueResolveRequest): Promise<any> => {
        const [error, response] = await API('POST', `issues/${uuid}/resolve`, data);
        return [error, response];
    };

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'issues/all', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'issues/report', filter, undefined, undefined, 'blob');
        return [error, response];
    };

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `issues/${uuid}/coverFace`);
        return [error, response];
    };

    public static fetchOverview = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `issues/${uuid}/overview`);
        return [error, response];
    };

    public static updateOverview = async (uuid: string, data: IIssueRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `issues/${uuid}/overview`, data);
        return [error, response];
    };

    public static fetchAssignedOn = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `issues/${uuid}/assign`);
        return [error, response];
    };

    public static assignUser = async (uuid: string, userId?: string): Promise<any> => {
        const [error, response] = await API('POST', `issues/${uuid}/assign`, userId);
        return [error, response];
    };
}
export default IssueService;