import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useCustomer } from "../../../hooks/useCustomer";
import { ECriteriaExpression, EReputationLevel, ICriteria } from "../../../models/CommonModels";
import { ECustomerStatus } from "../../../models/CustomerModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import DateRangeField from "../../Base/DateComponent/DateRangeField";
import ReputationSelect from "../../Base/ReputationComponent/ReputationSelect";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const CustomerFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useCustomer();
    const { criterias } = useSelector((state: RootState) => state.customerSlice.grid);

    const [idno, setIdno] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [status, setStatus] = useState<ECustomerStatus | undefined>(undefined);
    const [reputation, setReputation] = useState<EReputationLevel | undefined>(undefined);
    const [lastLoadDateFrom, setLastLoadDateFrom] = useState<number | undefined>(undefined);
    const [lastLoadDateTo, setLastLoadDateTo] = useState<number | undefined>(undefined);

    const statusData = useRef<string[]>(
        Object.keys(ECustomerStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== ECustomerStatus[ECustomerStatus.NONE])
            .map(key => key.toString())
    );

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || '');
        setName(getValueFromCriterias('name', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as ECustomerStatus);

        const reputationStr: string | undefined = getValueFromCriterias('reputationLevel', criterias);
        setReputation(reputationStr as EReputationLevel);

        const lastLoadDateRangeStr: string = getValueFromCriterias('lastLoadDate', criterias) || '';
        if (isBlank(lastLoadDateRangeStr)) {
            setLastLoadDateFrom(undefined);
            setLastLoadDateTo(undefined);
        } else {
            const lastLoadDateRange: string[] = lastLoadDateRangeStr.split(',');
            setLastLoadDateFrom(Number(lastLoadDateRange[0]));
            setLastLoadDateTo(Number(lastLoadDateRange[1]));
        }
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIdno(event.target.value);
    }, []);

    const onChangeNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as ECustomerStatus);
    }, []);

    const onChangeReputationHandler = useCallback((event?: SelectChangeEvent) => {
        setReputation(event?.target.value as unknown as EReputationLevel);
    }, []);

    const onChangeLastLoadDateRangeHandler = useCallback((fromDate?: number, toDate?: number) => {
        setLastLoadDateFrom(fromDate);
        setLastLoadDateTo(toDate);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isBlank(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (name && !isBlank(name)) {
            newCriterias.push({
                property: 'name',
                value: name,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (status && status !== ECustomerStatus[ECustomerStatus.NONE]) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (reputation && reputation !== EReputationLevel[EReputationLevel.NONE]) {
            newCriterias.push({
                property: 'reputationLevel',
                value: reputation.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (lastLoadDateFrom && lastLoadDateTo) {
            newCriterias.push({
                property: 'lastLoadDate',
                value: `${lastLoadDateFrom},${lastLoadDateTo}`,
                expression: ECriteriaExpression.BETWEEN_DATE
            });
        }

        return newCriterias;
    }, [idno, lastLoadDateFrom, lastLoadDateTo, name, reputation, status]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('ID#')}
                        autoComplete='off'
                        slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                        value={idno}
                        onChange={onChangeIdnoHandler}
                    />

                    <ReputationSelect
                        label={t('REPUTATION')}
                        value={reputation}
                        onChange={onChangeReputationHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('NAME')}
                        autoComplete='off'
                        slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                        value={name}
                        onChange={onChangeNameHandler}
                    />

                    <DateRangeField
                        alignDirection='row'
                        label={t('LAST LOAD')}
                        requiredToFrom
                        fromDateLabel={t('FROM')}
                        toDateLabel={t('TO')}
                        toDateValue={lastLoadDateTo}
                        fromDateValue={lastLoadDateFrom}
                        onChange={onChangeLastLoadDateRangeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData.current}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        idno, lastLoadDateFrom, lastLoadDateTo, name, onChangeIdnoHandler,
        onChangeLastLoadDateRangeHandler, onChangeNameHandler, onChangeReputationHandler,
        onChangeStatusHandler, reputation, status, t
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default CustomerFilter;