import { Box, Paper, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { decodeFromBase64 } from '../../../helpers/EncoderDecoderHelper';
import { ICriteria } from '../../../models/CommonModels';

const rootStyle = {
    width: '100%',
    height: '100%',
    overflowX: 'scroll'
};

const rootSecondStyle = {
    height: '100%',
    position: 'relative'
};

const stepStyle = {
    height: '99.7%',
    maxHeight: '99.7%',
    width: '50%',
    position: 'absolute',
    top: 0,
    right: 0,
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
};

interface IProps {
    onApplyFilter: (criterias: ICriteria[]) => void;
    onResetState: () => void;
    mainSection: JSX.Element;
    stepSection: JSX.Element;
}
const BasePage = (props: IProps) => {
    const { onApplyFilter, onResetState, mainSection, stepSection } = props;
    const xxlSize = useMediaQuery('(min-width:2800px)');
    const xlSize = useMediaQuery('(min-width:1920px)');
    const lSize = useMediaQuery('(min-width:1600px)');
    const mSize = useMediaQuery('(min-width:1366px)');
    const sSize = useMediaQuery('(min-width:1024px)');

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [showComponent, setShowComponent] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams.has('filter')) {
            const filterEncoded: string = searchParams.get('filter') || '';
            const filterDecoded: string = decodeFromBase64(filterEncoded);
            const criterias: ICriteria[] = JSON.parse(filterDecoded);
            onApplyFilter(criterias);
        }

        setShowComponent(true);

        return () => onResetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (xxlSize) {
        stepStyle.width = '40%';
    } else if (xlSize) {
        stepStyle.width = '50%';
    } else if (lSize) {
        stepStyle.width = '67%';
    } else if (mSize) {
        stepStyle.width = '75%';
    } else if (sSize) {
        stepStyle.width = '85%';
    } else {
        stepStyle.width = '100%';
    }

    return (
        <>
            {showComponent &&
                <Box sx={rootStyle}>
                    <Box sx={rootSecondStyle}>
                        <Box height={'100%'}>
                            {mainSection}
                        </Box>

                        {id &&
                            <Paper sx={stepStyle} variant='outlined'>
                                {stepSection}
                            </Paper>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}
export default BasePage;