import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, List, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IMenuAppBarBtn } from '../../models/CommonModels';
import Logo from '../Base/LogoComponent/Logo';
import DrawerMenuBtn from './DrawerMenuBtn';

const menuIconStyle = {
    color: 'white',
    flexDirection: 'column',
    gap: 0.5
};

interface IProps {
    items: IMenuAppBarBtn[];
    activePath: string;
    onClick: (path: string) => void;
}
const DrawerMenu = (props: IProps) => {
    const { items, activePath, onClick } = props;
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const onOpenToggleHandler = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const onClickHandler = useCallback((path: string) => {
        onClick(path);
        onOpenToggleHandler();
    }, [onClick, onOpenToggleHandler]);

    return (
        <>
            <Box>
                <Tooltip title={t('Menu')} placement={'bottom'} arrow>
                    <span>
                        <Button sx={menuIconStyle} onClick={onOpenToggleHandler}>
                            <MenuIcon />
                            <Typography variant='caption'>{t('Menu')}</Typography>
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            <Box marginLeft={4}>
                <Logo heigth={55} width={70} color={'white'} />
            </Box>

            <Drawer anchor={'left'} open={open} onClose={onOpenToggleHandler}>
                <List>
                    {
                        items.map((item: IMenuAppBarBtn, index: number) => (
                            <DrawerMenuBtn
                                key={`drawer-item-${index}`}
                                item={item}
                                active={item.path.startsWith(activePath)}
                                onClick={onClickHandler}
                            />
                        ))
                    }
                </List>
            </Drawer>
        </>
    );
}
export default DrawerMenu;