import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { EBrandType, IBrandRequestDto, IBrandResponseDto } from "../../../models/MerchandiseModels";
import { EFuelType, ETransmissionType, EVehicleType, IVehicleRequestDto, IVehicleResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import { RootState } from "../../../store/store";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import BrandAutocomplete from "../../Base/BrandComponent/BrandAutocomplete";
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import FuelTypeSelect from "../FuelTypeSelect";
import TransmissionTypeSelect from "../TransmissionTypeSelect";
import TransportTypeSelect from "../TransportTypeSelect";

const emptyBrand: IBrandRequestDto = {
    uuid: '',
    name: '',
    type: EBrandType.NONE
};

interface IProps {
    vehicle?: IVehicleResponseDto;

    open: boolean;
    type?: EVehicleType;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const VehicleDialog = (props: IProps) => {
    const { open, type, vehicle, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'vehicle-form';

    const { measureSize, measureMass, measureConsumption, measureEnginePower, measureEngineVolume } = useSelector((state: RootState) => state.userProfileSlice.profile.userPreference);

    const isEdit = useRef<boolean>(vehicle !== undefined);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IVehicleRequestDto>({
        defaultValues: {
            brand: isEdit.current ? vehicle?.brand : emptyBrand,
            model: isEdit.current ? vehicle?.model : '',
            type: isEdit.current ? vehicle?.type : type || EVehicleType.NONE,
            year: isEdit.current ? vehicle?.year : NaN,
            length: isEdit.current ? vehicle?.length : NaN,
            width: isEdit.current ? vehicle?.width : NaN,
            height: isEdit.current ? vehicle?.height : NaN,
            weight: isEdit.current ? vehicle?.weight : NaN,
            grossWeight: isEdit.current ? vehicle?.grossWeight : NaN,
            fuelType: isEdit.current ? vehicle?.fuelType : undefined,
            fuelConsumption: isEdit.current ? vehicle?.fuelConsumption : undefined,
            transmissionType: isEdit.current ? vehicle?.transmissionType : undefined,
            enginePower: isEdit.current ? vehicle?.enginePower : undefined,
            engineCapacity: isEdit.current ? vehicle?.engineCapacity : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: IVehicleRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.updateVehicle(uuid, data);
            if (response) {
                displayNotification({ message: t('Vehicle was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IVehicleRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.createVehicle(data);
            if (response) {
                displayNotification({ message: t('Vehicle was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick(response.data.response.entityId);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IVehicleRequestDto) => {
        if (vehicle) {
            updateData(vehicle.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, vehicle, updateData]);

    const validateTypeField = useCallback((value: EVehicleType) => {
        return EVehicleType[value] !== undefined && value !== EVehicleType.NONE;
    }, []);

    register('type', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EVehicleType = event.target.value as EVehicleType;
        setValue('type', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateBrandField = useCallback((value: IBrandRequestDto | null) => {
        return value !== null;
    }, []);

    register('brand', { validate: validateBrandField });
    const onBrandChangeHandler = useCallback((value: IBrandResponseDto | null) => {
        setValue('brand', value === null ? emptyBrand : { ...value }, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateModel = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('model', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('year', { validate: validateAmountField });
    const onYearChangeField = useCallback((value: number) => {
        setValue('year', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('length', { validate: validateAmountField });
    const onLengthChangeField = useCallback((value: number) => {
        setValue('length', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('width', { validate: validateAmountField });
    const onWidthChangeField = useCallback((value: number) => {
        setValue('width', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('height', { validate: validateAmountField });
    const onHeightChangeField = useCallback((value: number) => {
        setValue('height', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('weight', { validate: validateAmountField });
    const onWeightChangeField = useCallback((value: number) => {
        setValue('weight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('grossWeight', { validate: validateAmountField });
    const onGrossWeightChangeField = useCallback((value: number) => {
        setValue('grossWeight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFuelField = useCallback((value: EFuelType | undefined) => {
        const type: EVehicleType = getValues('type');
        return EVehicleType.TRAILER === type
            ? true
            : value !== undefined && EFuelType[value] !== undefined && value !== EFuelType.NONE;
    }, [getValues]);

    register('fuelType', { validate: validateFuelField });
    const onFuelChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFuelType = event.target.value as EFuelType;
        setValue('fuelType', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAmountForTransportField = useCallback((value: number | undefined) => {
        const type: EVehicleType = getValues('type');
        return EVehicleType.TRAILER === type
            ? true
            : value !== undefined && !isNaN(value);
    }, [getValues]);

    register('fuelConsumption', { validate: validateAmountForTransportField });
    const onFuelConsumptionChangeField = useCallback((value: number) => {
        setValue('fuelConsumption', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validatTransmissionField = useCallback((value: ETransmissionType | undefined) => {
        const type: EVehicleType = getValues('type');
        return EVehicleType.TRAILER === type
            ? true
            : value !== undefined && ETransmissionType[value] !== undefined && value !== ETransmissionType.NONE;
    }, [getValues]);

    register('transmissionType', { validate: validatTransmissionField });
    const onTransmissionChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ETransmissionType = event.target.value as ETransmissionType;
        setValue('transmissionType', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('enginePower', { validate: validateAmountForTransportField });
    const onEnginePowerChangeField = useCallback((value: number) => {
        setValue('enginePower', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('engineCapacity', { validate: validateAmountForTransportField });
    const onEngineCapacityChangeField = useCallback((value: number) => {
        setValue('engineCapacity', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction='column' spacing={2}>
                    <Stack direction='row' spacing={2}>
                        <TransportTypeSelect
                            required
                            label={t('TYPE')}
                            value={getValues('type')}
                            onChange={onTypeChangeHandler}
                            readonly={type !== undefined}
                        />

                        <BrandAutocomplete
                            required
                            label={t('BRAND')}
                            type={getValues('type') as unknown as EBrandType}
                            value={getValues('brand').name}
                            onChange={onBrandChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <TextField
                            {...register('model', { validate: validateModel })}
                            label={t('MODEL')}
                            required
                            fullWidth
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                            autoComplete='off'
                        />

                        <NumberField
                            required
                            label={t('YEAR')}
                            value={getValues('year')}
                            allowNegative={false}
                            scale={0}
                            thousandSeparator={false}
                            onChange={onYearChangeField}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <NumberField
                            required
                            label={t('LENGTH')}
                            value={getValues('length')}
                            allowNegative={false}
                            thousandSeparator={false}
                            units={measureSize}
                            onChange={onLengthChangeField}
                        />

                        <NumberField
                            required
                            label={t('WIDTH')}
                            value={getValues('width')}
                            allowNegative={false}
                            thousandSeparator={false}
                            units={measureSize}
                            onChange={onWidthChangeField}
                        />

                        <NumberField
                            required
                            label={t('HEIGHT')}
                            value={getValues('height')}
                            allowNegative={false}
                            thousandSeparator={false}
                            units={measureSize}
                            onChange={onHeightChangeField}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <NumberField
                            required
                            label={t('WEIGHT')}
                            value={getValues('weight')}
                            allowNegative={false}
                            thousandSeparator={false}
                            units={measureMass}
                            onChange={onWeightChangeField}
                        />

                        <NumberField
                            required
                            label={t('GROSS WEIGHT')}
                            value={getValues('grossWeight')}
                            allowNegative={false}
                            thousandSeparator={false}
                            units={measureMass}
                            onChange={onGrossWeightChangeField}
                        />
                    </Stack>

                    {getValues('type') !== EVehicleType.TRAILER &&
                        <Stack direction='row' spacing={2}>
                            <FuelTypeSelect
                                required
                                label={t('FUEL TYPE')}
                                value={getValues('fuelType')}
                                onChange={onFuelChangeHandler}
                            />

                            <NumberField
                                required
                                label={t('FUEL CONSUMPTION')}
                                value={getValues('fuelConsumption') || NaN}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={measureConsumption}
                                onChange={onFuelConsumptionChangeField}
                            />

                            <TransmissionTypeSelect
                                required
                                label={t('TRANSMISSION TYPE')}
                                value={getValues('transmissionType')}
                                onChange={onTransmissionChangeHandler}
                            />
                        </Stack>
                    }

                    {getValues('type') !== EVehicleType.TRAILER &&
                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('ENGINE POWER')}
                                value={getValues('enginePower') || NaN}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={measureEnginePower}
                                onChange={onEnginePowerChangeField}
                            />

                            <NumberField
                                required
                                label={t('ENGINE CAPACITY')}
                                value={getValues('engineCapacity') || NaN}
                                allowNegative={false}
                                thousandSeparator={false}
                                units={measureEngineVolume}
                                onChange={onEngineCapacityChangeField}
                            />
                        </Stack>
                    }
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, measureConsumption, measureEnginePower,
        measureEngineVolume, measureMass, measureSize, onBrandChangeHandler,
        onEngineCapacityChangeField, onEnginePowerChangeField,
        onFuelChangeHandler, onFuelConsumptionChangeField,
        onGrossWeightChangeField, onHeightChangeField, onLengthChangeField,
        onSubmit, onTransmissionChangeHandler, onTypeChangeHandler,
        onWeightChangeField, onWidthChangeField, onYearChangeField, register,
        t, type, validateModel
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} VEHICLE`)}
            maxWidth={'md'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default VehicleDialog;