import CommuteIcon from '@mui/icons-material/Commute';
import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertTimestampToDateStrWithFormatOrEmpty, getDateAndTimeFormat } from "../../../helpers/dateHelper";
import { normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { IBaseGridActionBtn, IFilter } from '../../../models/CommonModels';
import { ETransportStatus, ITransportResponseDto } from '../../../models/TransportModels';
import TransportService from '../../../services/TransportService';
import { RootState } from "../../../store/store";
import AvatarComponent from '../../Base/AvatarComponent/AvatarComponent';
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import VehicleManagementDialog from '../Vehicle/VehicleManagementDialog';
import TransportDialog from './TransportDialog';
import TransportFilter from './TransportFilter';

const TransportGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.transportSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [vehicleToggle, setVehicleToggle] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return TransportService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return TransportService.downloadReport(filter);
    }, []);

    const onAvatarColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                <AvatarComponent data={params.value} width={27} height={27} />
            </Box>
        );
    }, []);

    const onNameColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ITransportResponseDto = params.row;
        const value: string = `${row.brand} ${row.model} ${row.year}`;
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onIssueColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ITransportResponseDto = params.row;
        const done: number = row.numberOfDoneIssues || 0;
        const inProgress: number = row.numberOfInProgressIssues || 0;
        const open: number = row.numberOfOpenIssues || 0;
        const unresolved: number = row.numberOfUnresolvedIssues || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('OPEN: ')} ${open}`}>
                    <span style={{ color: '#ed6c02' }}>{open}</span>
                </Tooltip>

                <Tooltip title={`${t('IN PROGRESS: ')} ${inProgress}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{inProgress}</span>
                </Tooltip>

                <Tooltip title={`${t('DONE: ')} ${done}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{done}</span>
                </Tooltip>

                <Tooltip title={`${t('UNRESOLVED: ')} ${unresolved}`}>
                    <span style={{ color: 'rgb(198, 40, 40)' }}>{unresolved}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const getActiveStatusDate = useCallback((activeFromDate?: number, activeToDate?: number): string => {
        if (activeFromDate && activeToDate) {
            return `${convertDateToString(activeFromDate)} - ${convertDateToString(activeToDate)}`;
        }
        return activeFromDate ? `${t('FROM ')} ${convertDateToString(activeFromDate)}` : `${t('TO ')} ${convertDateToString(activeToDate)}`;
    }, [convertDateToString, t]);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ETransportStatus = params.value;

        let result: string = value.toString().replaceAll('_', ' ');
        if (value === ETransportStatus.TEMPORARY) {
            const row: ITransportResponseDto = params.row;
            result += ` ${getActiveStatusDate(row.activeFromDate, row.activeToDate)}`;
            return (
                <Tooltip title={result}>
                    <span>{result}</span>
                </Tooltip>
            );
        }

        return result;
    }, [getActiveStatusDate]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onUpdatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const updatedBy: string = params.row.updatedBy;
        const updatedDate: number = params.row.updatedDate;
        if (!updatedBy) {
            return '';
        }

        const dateTime: string = getDateAndTimeFormat(updatedDate, dateFormat, timeFormat);
        const tooltip: string = `${updatedBy} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {updatedBy} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            getActions: onActionsColumnRender
        }, {
            field: 'favorite',
            headerName: '',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onFavoriteColumnRender
        }, {
            field: 'avatar',
            headerName: '',
            width: 45,
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            disableColumnMenu: true,
            renderCell: onAvatarColumnRender
        }, {
            field: 'name',
            headerName: t('NAME'),
            flex: 1,
            minWidth: 350,
            headerAlign: 'center',
            sortable: false,
            renderCell: onNameColumnRender
        }, {
            field: 'type',
            headerName: t('TYPE'),
            width: 120,
            headerAlign: 'center',
            sortable: false,
            valueFormatter: onTypeColumnRender
        }, {
            field: 'vin',
            headerName: t('VIN'),
            width: 200,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'licensePlate',
            headerName: t('LICENSE PLATE'),
            width: 150,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'transportNumber',
            headerName: t('NUMBER'),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'color',
            headerName: t('COLOR'),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'issue',
            headerName: t('ISSUE'),
            width: 200,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onIssueColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 270,
            headerAlign: 'center',
            sortable: false,
            renderCell: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onCreatedColumnRender
        }, {
            field: 'updated',
            headerName: t('UPDATED'),
            width: 180,
            headerAlign: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onUpdatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAvatarColumnRender, onCreatedColumnRender, onFavoriteColumnRender,
        onIssueColumnRender, onNameColumnRender, onStatusColumnRender, onTooltipColumnRender,
        onTypeColumnRender, onUpdatedColumnRender, t
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onVehicleToggleHandler = useCallback(() => {
        setVehicleToggle(vehicleToggle => !vehicleToggle);
    }, []);

    const actionBtns: IBaseGridActionBtn[] = [{
        tooltip: t('Vehicle management'),
        onClick: onVehicleToggleHandler,
        icon: <CommuteIcon />,
        key: 'vehicle-action-btn',
        iconBtn: true
    }];

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create transport')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search transport(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download transport(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                actionBtns={actionBtns}
            />

            {filterToggle &&
                <TransportFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <TransportDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }

            {vehicleToggle &&
                <VehicleManagementDialog
                    open={vehicleToggle}
                    onCloseBtnClick={onVehicleToggleHandler}
                />
            }
        </>
    );
}
export default TransportGrid;