import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import PublicIcon from '@mui/icons-material/Public';
import ShieldIcon from '@mui/icons-material/Shield';
import { Box, Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store/store';
import AvatarComponent from '../Base/AvatarComponent/AvatarComponent';

const submenuSlotProps = {
    paper: {
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
        }
    }
};

interface IProfileMenuBtn {
    label: string;
    tooltip: string;
    icon: ReactNode;
    disabled?: boolean;
    onClick: () => void;
    divider?: boolean;
};
const ProfileMenuBtn = () => {
    const { userProfile } = useSelector((state: RootState) => state.userProfileSlice.profile);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onCloseHandler = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const items = useMemo((): IProfileMenuBtn[] => {
        return [{
            label: t('PERSONAL PROFILE'),
            tooltip: 'Personal profile management',
            icon: <AccountCircleIcon fontSize="small" />,
            onClick: () => console.log(''),
            disabled: true,
            divider: true
        }, {
            label: t('GLOBAL PROFILE'),
            tooltip: 'Global profile management',
            icon: <PublicIcon fontSize="small" />,
            onClick: () => console.log(''),
            disabled: true
        }, {
            label: t('PERMISSIONS'),
            tooltip: 'Permissions management',
            icon: <ShieldIcon fontSize="small" />,
            onClick: () => console.log(''),
            disabled: true,
            divider: true
        }, {
            label: t('HELP & PRIVACY'),
            tooltip: 'Help & privacy management',
            icon: <InfoIcon fontSize="small" />,
            onClick: () => console.log(''),
            disabled: true
        }, {
            label: t('QUOTES'),
            tooltip: 'Quotes management',
            icon: <FormatQuoteIcon fontSize="small" />,
            onClick: () => console.log(''),
            disabled: true,
            divider: true
        }, {
            label: t('LOGOUT'),
            tooltip: 'Logout',
            icon: <LogoutIcon fontSize="small" />,
            onClick: () => navigate('logout')
        }];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <>
            <Button
                aria-label='Profile menu'
                color='inherit'
                onClick={onClickHandler}
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
            >
                <AvatarComponent
                    data={userProfile.avatar}
                    width='45px'
                    height='45px'
                />

                <Box marginLeft={2}>
                    <Typography variant={'body2'} align='left'>
                        {userProfile.firstName.toUpperCase()} {userProfile.lastName.toUpperCase()}
                    </Typography>
                    <Typography variant={'body2'} align='left'>
                        {userProfile.role.name.toString().replaceAll('_', ' ').toUpperCase()}
                    </Typography>
                </Box>

                <ExpandMoreIcon sx={{ marginLeft: '10px' }} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="profile-menu"
                open={open}
                onClose={onCloseHandler}
                onClick={onCloseHandler}
                slotProps={submenuSlotProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    items.map((item: IProfileMenuBtn, index: number) => (
                        <div key={`profile-menu-item-${index}`}>
                            <MenuItem onClick={item.onClick} disabled={item.disabled}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <Typography variant='caption'>{item.label}</Typography>
                            </MenuItem>
                            {item.divider && <Divider />}
                        </div>
                    ))
                }
            </Menu>
        </>
    );
}
export default ProfileMenuBtn;