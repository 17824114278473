import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";

interface IProps {
    label?: string;
    value?: number;
    size?: 'small' | 'medium';
    min?: number;
    max?: number;
    required?: boolean;
    fullWidth?: boolean;
    onChange?: (timestamp?: number) => void;
}
const DateField = (props: IProps) => {
    const { label = 'DATE', size = 'small', max, min, required, fullWidth = true, value, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<any>(value ? dayjs(value) : null);

    const onChangeHandler = useCallback((newValue: any) => {
        if (onChange) {
            const value: Dayjs = dayjs(newValue);
            const timestamp: number = value.toDate().getTime();
            onChange(isNaN(timestamp) ? undefined : timestamp);
        }
        setSelectedValue(newValue);
    }, [onChange]);

    return (
        <DatePicker
            label={label}
            maxDate={max ? dayjs(max) : null}
            minDate={min ? dayjs(min) : null}
            value={selectedValue}
            slotProps={{
                textField: {
                    required: required,
                    fullWidth: fullWidth,
                    size: size
                },
                field: { clearable: true },
                actionBar: {
                    actions: ['today', 'clear']
                }
            }}
            onChange={onChangeHandler}
        />
    );
}
export default DateField;