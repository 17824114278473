import { Autocomplete, AutocompleteRenderInputParams, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { EVehicleType, IVehicleAutocompleteResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";

interface IProps {
    label?: string;
    required?: boolean;
    type: EVehicleType;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    showDetails?: boolean;
    refresh?: boolean;
    onInit?: (value: IVehicleAutocompleteResponseDto | null) => void;
    onChange?: (newValue: IVehicleAutocompleteResponseDto | null) => void;
}
const VehicleAutocomplete = (props: IProps) => {
    const {
        size = 'medium', disabled, label = 'Vehicle', required = false, onChange, onInit,
        value, showDetails = false, refresh, type
    } = props;

    const [data, setData] = useState<IVehicleAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<IVehicleAutocompleteResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);

        if (EVehicleType[type] !== undefined && type !== EVehicleType.NONE) {
            (async () => {
                const [, response] = await TransportService.fetchVehicleAutocomplete(type);
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [type, refresh]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IVehicleAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: IVehicleAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: IVehicleAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>{item.name}</Typography>
                {showDetails &&
                    <Typography variant='caption' sx={{ fontSize: 11 }}>
                        <Divider />
                        {item.year}{item.fuelType ? ` [ ${item.fuelType.toString()} ]` : ''}
                    </Typography>
                }
            </div>
        );
    }, [showDetails]);

    const onBuildOptionLabel = useCallback((item: IVehicleAutocompleteResponseDto) => {
        return item.name;
    }, []);

    const onBuildOptionKey = useCallback((item: IVehicleAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    );
}
export default VehicleAutocomplete;