import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { useUser } from "../../../../hooks/useUser";
import { IUserEmployeeRequestDto, IUserEmployeeResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../../Base/DateComponent/DateField";
import NumberField from "../../../Base/NumberFieldComponent/NumberField";

interface IProps {
    open: boolean;
    userId: string;
    entity?: IUserEmployeeResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserEmployeeDialog = (props: IProps) => {
    const { open, userId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useUser();
    const formId: string = 'user-employee-form';

    const [loading, setLoading] = useState(false);
    const [maxFromDate, setMaxFromDate] = useState<number | undefined>(entity ? entity.endDate : undefined);
    const [minToDate, setMinToDate] = useState<number | undefined>(entity ? entity.startDate : undefined);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty } } = useForm<IUserEmployeeRequestDto>({
        defaultValues: {
            startDate: entity ? entity.startDate : NaN,
            endDate: entity ? entity.endDate : NaN,
            workload: entity ? entity.workload : NaN
        }
    });

    const onSubmit = useCallback((data: IUserEmployeeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updateEmployee(userId, data);
            if (response) {
                displayNotification({ message: t('User employee was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, gridRefresh, onCloseBtnClick, onSubmitBtnClick, stepRefresh, t]);

    register('startDate');
    const onChangeStartDateHandler = useCallback((timestamp?: number) => {
        setValue('startDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMinToDate(timestamp);
    }, [setValue]);

    register('endDate');
    const onChangeEndDateHandler = useCallback((timestamp?: number) => {
        setValue('endDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMaxFromDate(timestamp);
    }, [setValue]);

    register('workload');
    const onChangeWorkloadHandler = useCallback((value?: number) => {
        setValue('workload', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <DateField
                        label={t('EMPLOYMENT DATE')}
                        size='medium'
                        value={getValues('startDate')}
                        onChange={onChangeStartDateHandler}
                        max={maxFromDate}
                    />

                    <DateField
                        label={t('TERMINATION DATE')}
                        size='medium'
                        value={getValues('endDate')}
                        onChange={onChangeEndDateHandler}
                        min={minToDate}
                    />

                    <NumberField
                        label={t('WORKLOAD')}
                        units={'h'}
                        allowNegative={false}
                        value={getValues('workload')}
                        onChange={onChangeWorkloadHandler}
                    />
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, maxFromDate, minToDate, onChangeEndDateHandler,
        onChangeStartDateHandler, onChangeWorkloadHandler, onSubmit, t
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT EMPLOYEE')}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserEmployeeDialog;