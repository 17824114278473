import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthProvider } from './context/AuthProvider';
import Routes from './routes/Routes';
import { RootState } from "./store/store";
import { BreakpointsOptions, ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
    }
};

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920
    }
};

const darkTheme = createTheme({
    palette: { mode: 'dark', },
    breakpoints: breakpoints
});

const lightTheme = createTheme({
    palette: { mode: 'light', },
    breakpoints: breakpoints
});

const App = () => {
    const { darkMode } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const [mode, setMode] = useState(darkMode);

    useEffect(() => { setMode(darkMode); }, [darkMode]);

    return (
        <>
            <ThemeProvider theme={mode ? darkTheme : lightTheme}>
                <AuthProvider>
                    <Routes />
                </AuthProvider>
            </ThemeProvider>
        </>
    );
}

export default App;