import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { useTransport } from "../../../../hooks/useTransport";
import { ETransportStatus, ITransportStatusRequestDto, ITransportStatusResponseDto } from "../../../../models/TransportModels";
import TransportService from "../../../../services/TransportService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../../Base/DateComponent/DateField";
import StatusSelect from "../../../Base/StatusSelectComponent/StatusSelect";

const statusData: string[] = Object.keys(ETransportStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ETransportStatus[ETransportStatus.NONE])
    .filter(key => key !== ETransportStatus.INCOMPLETE)
    .map(key => key.toString())

interface IProps {
    open: boolean;
    entity: ITransportStatusResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const TransportStatusDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useTransport();
    const formId: string = 'transport-status-form';

    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<ETransportStatus>(entity.status);
    const [maxActiveFromDate, setMaxActiveFromDate] = useState<number | undefined>(entity.status === ETransportStatus.TEMPORARY && entity.activeToDate ? entity.activeToDate : undefined);
    const [minActiveToDate, setMinActiveToDate] = useState<number | undefined>(entity.status === ETransportStatus.TEMPORARY && entity.activeFromDate ? entity.activeFromDate : undefined);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty } } = useForm<ITransportStatusRequestDto>({
        defaultValues: {
            uuid: entity.uuid,
            status: entity.status,
            activeFromDate: entity.activeFromDate,
            activeToDate: entity.activeToDate
        }
    });

    const onSubmit = useCallback((data: ITransportStatusRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.updateStatus(data);
            if (response) {
                displayNotification({ message: t('Transport status was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmitBtnClick, t]);

    const validateStatusType = useCallback((value: ETransportStatus) => {
        return ETransportStatus[value] !== undefined && value !== ETransportStatus[ETransportStatus.NONE];
    }, []);

    register('status', { validate: validateStatusType });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        const status: ETransportStatus = event.target.value as ETransportStatus;
        setValue('status', status, {
            shouldValidate: true,
            shouldDirty: true
        });
        setStatus(status);
    }, [setValue]);

    const validateActiveDate = useCallback(() => {
        if (status !== ETransportStatus.TEMPORARY) {
            return true;
        }

        if (getValues('activeFromDate') === undefined && getValues('activeToDate') === undefined) {
            return false;
        }

        return true;
    }, [getValues, status]);

    register('activeFromDate', { validate: validateActiveDate });
    const onChangeActiveFromDateHandler = useCallback((timestamp?: number) => {
        setValue('activeFromDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMinActiveToDate(timestamp);
    }, [setValue]);

    register('activeToDate', { validate: validateActiveDate });
    const onChangeActiveToDateHandler = useCallback((timestamp?: number) => {
        setValue('activeToDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMaxActiveFromDate(timestamp);
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData}
                        value={getValues('status')}
                        size='medium'
                        onChange={onChangeStatusHandler}
                    />

                    {status === ETransportStatus.TEMPORARY &&
                        <DateField
                            label={t('ACTIVE FROM')}
                            size='medium'
                            value={getValues('activeFromDate')}
                            onChange={onChangeActiveFromDateHandler}
                            max={maxActiveFromDate}
                        />
                    }

                    {status === ETransportStatus.TEMPORARY &&
                        <DateField
                            label={t('ACTIVE TO')}
                            size='medium'
                            value={getValues('activeToDate')}
                            onChange={onChangeActiveToDateHandler}
                            min={minActiveToDate}
                        />
                    }
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, maxActiveFromDate, minActiveToDate, onChangeActiveFromDateHandler,
        onChangeActiveToDateHandler, onChangeStatusHandler, onSubmit, status, t
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT STATUS')}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default TransportStatusDialog;