import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IMenuAppBarBtn } from '../../models/CommonModels';

const activeStyle = {
    backgroundColor: '#2f2f2f63',
    cursor: 'default',
    paddingLeft: 5,
    paddingRight: 5,
    '&:hover': {
        backgroundColor: '#2f2f2f63'
    }
};

const inactiveStyle = {
    paddingLeft: 5,
    paddingRight: 5
};

interface IProps {
    item: IMenuAppBarBtn;
    active?: boolean;
    onClick: (path: string) => void;
}
const DrawerMenuBtn = (props: IProps) => {
    const { item, active = false, onClick } = props;
    const { t } = useTranslation();

    const onClickHandler = useCallback(() => {
        if (!active) {
            onClick(item.path);
        }
    }, [active, item.path, onClick]);

    const style: {} = active ? activeStyle : inactiveStyle;

    return (
        <Tooltip title={t(item.tooltip)} placement={'right'} arrow>
            <span>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={style}
                        disabled={item.disabled}
                        onClick={onClickHandler}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={t(item.label)} />
                    </ListItemButton>
                </ListItem>
            </span>
        </Tooltip>
    );
}
export default DrawerMenuBtn;