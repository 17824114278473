import { Box, Paper } from "@mui/material";
import IssueDocument from "./IssueDocument";

interface IProps {
    entityId: string;
}
const IssueDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <IssueDocument issueId={props.entityId} />
            </Box>
        </Paper>
    );
}
export default IssueDocumentsStep;