import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Divider, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from '../../../helpers/textHelper';
import { IAddressRequestDto, IAddressShortResponseDto, ILocationRequestDto } from "../../../models/AddressModels";
import AddressService from '../../../services/AddressService';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import AddressAutocomplete from "./AddressAutocomplete";
import GoogleMap from './GoogleMap';

const defaultLocation: ILocationRequestDto = {
    country: '',
    state: '',
    county: '',
    city: '',
    village: ''
};

const defaultAddress: IAddressRequestDto = {
    location: defaultLocation,
    street: '',
    zipcode: '',
    latitude: NaN,
    longitude: NaN
};

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onApplyBtnClick: (address: IAddressShortResponseDto) => void;
}
const AddressDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onApplyBtnClick } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [address, setAddress] = useState<IAddressRequestDto>(defaultAddress);
    const [street, setStreet] = useState<string>('');

    const onApplyBtnClickHandler = useCallback(() => {
        if (address) {
            (async () => {
                setLoading(true);

                let normalizedAddress: IAddressRequestDto = address;
                if (!isBlank(street)) {
                    const streetAsStr: string = `${address.street}, ${street}`;
                    normalizedAddress = { ...address, street: streetAsStr };
                }

                const [error, response] = await AddressService.fetchOrCreate(normalizedAddress);
                if (response) {
                    onApplyBtnClick({ uuid: response.uuid, value: response.fullAddress });

                    setLoading(false);
                    onCloseBtnClick();
                }

                if (error) {
                    setLoading(false);
                }
            })();
        }
    }, [address, onApplyBtnClick, onCloseBtnClick, street]);

    const onChangeAddressHandler = useCallback((address: IAddressRequestDto | null) => {
        setAddress(address || defaultAddress);
    }, []);

    const onChangeStreetAddressHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({ ...address, street: event.target.value });
    }, [address]);

    const onChangeStreetHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setStreet(event.target.value);
    }, []);

    const onChangeZipcodeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({ ...address, zipcode: event.target.value });
    }, [address]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={1}>
                <AddressAutocomplete
                    onChange={onChangeAddressHandler}
                />

                <Divider />

                <Stack direction='row' spacing={2}>
                    {!isBlank(address.location.country) &&
                        <Stack flex={1} direction='column' spacing={1.5}>
                            <TextField
                                autoComplete='off'
                                required
                                fullWidth
                                label={t('COUNTRY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.country}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('STATE')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.state}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('COUNTY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.county}
                                error={isBlank(address.location.country)}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('CITY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.city}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('VILLAGE')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.village}
                            />

                            <TextField
                                autoComplete='off'
                                required
                                fullWidth
                                label={t('STREET')}
                                value={address?.street}
                                error={isBlank(address.street)}
                                onChange={onChangeStreetAddressHandler}
                            />

                            <Stack direction='row' spacing={1}>
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    label={t('***')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                    value={street}
                                    onChange={onChangeStreetHandler}
                                />

                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    label={t('ZIPCODE')}
                                    value={address?.zipcode}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                    onChange={onChangeZipcodeHandler}
                                />
                            </Stack>
                        </Stack>
                    }

                    <Box flex={2} minHeight={465}>
                        <GoogleMap
                            markers={(!isNaN(address.latitude) && !isNaN(address.longitude)) ? [{ lat: address.latitude, lng: address.longitude }] : []}
                        />
                    </Box>
                </Stack>
            </Stack >
        );
    }, [
        address.latitude, address.location.city, address.location.country,
        address.location.county, address.location.state, address.location.village,
        address.longitude, address.street, address.zipcode, onChangeAddressHandler,
        onChangeStreetAddressHandler, onChangeStreetHandler, onChangeZipcodeHandler,
        street, t
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={isBlank(address.location.country) || isBlank(address.street)}
                        onClick={onApplyBtnClickHandler}
                        size='small'
                        startIcon={<DoneIcon />}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [address, onApplyBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('ADDRESS MANAGEMENT')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default AddressDialog;