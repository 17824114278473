import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertTimestampToDateStrWithFormatOrEmpty } from '../../../../helpers/dateHelper';
import { IItemDetails } from '../../../../models/CommonModels';
import { IDocument } from "../../../../models/DocumentModels";
import { RootState } from '../../../../store/store';
import BaseDocumentItem from "../../../Base/BaseDocumentComponent/BaseDocumentItem";

interface IProps {
    document: IDocument;
    expanded: boolean;
    maxHeight: string | number;

    onChangeAcordeon: (value: string | false) => void;
    onEditBtnClick: (document: IDocument) => void;
    onDeleteBtnClick: (uuid: string) => void;
    onSubmitAttachment: (documentId: string, file: File) => void;
    onDeleteAttachment: (documentId: string, attachmentId: string) => void;
}
const TransportDocumentItem = (props: IProps) => {
    const {
        document, expanded, maxHeight, onEditBtnClick, onDeleteBtnClick,
        onChangeAcordeon, onSubmitAttachment, onDeleteAttachment
    } = props;

    const { t } = useTranslation();
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const convertDateToString = useCallback((value?: number) => {
        return value ? convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat) : '';
    }, [dateFormat]);

    const itemsDetails: IItemDetails[] = [{
        id: document.uuid,
        primary: t('TYPE'),
        secondary: document.type.replace('_', ' ')
    }, {
        id: document.uuid,
        primary: t('NUMBER'),
        secondary: document.number
    }, {
        id: document.uuid,
        primary: t('NOTES'),
        secondary: document.caption
    }, {
        id: document.uuid,
        primary: t('EXPIRATION DATE'),
        secondary: convertDateToString(document.expirationDate)
    }, {
        id: document.uuid,
        primary: t('CREATED'),
        secondary: convertDateToString(document.createdDate)
    }];

    const documentCaption: string = `${document.caption ? ` [ ${document.caption} ] ` : ''}`;
    const createdDdate: string = ` [ ${convertDateToString(document.createdDate)} ]`;
    const summary: string = `${document.type.replace('_', ' ')} ${documentCaption} ${createdDdate}`;

    return (
        <>
            <BaseDocumentItem
                document={document}
                expanded={expanded}
                maxHeight={maxHeight}
                onChangeAcordeon={onChangeAcordeon}
                summary={summary}
                itemsDetails={itemsDetails}
                editBtnLabel={t('EDIT')}
                editBtnTooltip={t('Edit document')}
                onEditBtnClick={onEditBtnClick}
                deleteBtnLabel={t('DELETE')}
                deleteBtnTooltip={t('Delete document')}
                onDeleteBtnClick={onDeleteBtnClick}
                addAttachmentBtnLabel={t('ATTACHMENT')}
                addAttachmentBtnTooltip={t('Add attachment')}
                onSubmitAttachment={onSubmitAttachment}
                onDeleteAttachment={onDeleteAttachment}
            />
        </>
    );
}
export default TransportDocumentItem;