import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Notification from '../components/Base/NotificationComponent/Notification';
import { AuthContext } from "../context/AuthProvider";
import { isBlank } from "../helpers/textHelper";
import { RootState } from "../store/store";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import NetworkHealthCheck from "../components/AuthModule/Control/NetworkHealthCheck";

const Routes = () => {
    const { authenticated } = useContext(AuthContext);
    const { userProfile } = useSelector((state: RootState) => state.userProfileSlice.profile);
    const [profileLoaded, setProfileLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (authenticated && !isBlank(userProfile.email)) {
            setProfileLoaded(true);
        } else {
            setProfileLoaded(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile])

    return (
        <>
            <Notification />
            <NetworkHealthCheck />

            {authenticated
                ? <ProtectedRoutes loaded={profileLoaded} />
                : <PublicRoutes />
            }
        </>
    );
}
export default Routes;